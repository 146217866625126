import { useState, useEffect } from "react";
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import CustomerHeader from "../../components/Headers/CustomerHeader";
import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

function MemberList() {
  const [members, setMembers] = useState([]);
  const history = useHistory();
  const clubId = session.get('clubInfo').clubId;

  useEffect(() => {
    // Fetch the list of members
    customerApi.getMembers({ club_id: clubId }).then((response) => {
      if (response.status === 200) {
        setMembers(response.data);
      } else {
        helper.showToast("Error", "Failed to load members", 3);
      }
    });
  }, [clubId]);

  const onEdit = (memberId) => {
    history.push(`/customer/edit-member/${memberId}`);
  };

  const onDelete = async (memberId) => {
    try {
      const response = await customerApi.deleteMember({member_id: memberId});
      if (response.status === 200) {
        helper.showToast("Success", "Member deleted successfully", 1);
        setMembers(members.filter((member) => member.id !== memberId)); // Remove from list
      } else {
        helper.showToast("Error", "Failed to delete member", 3);
      }
    } catch (error) {
      helper.showToast("Error", "Failed to delete member", 3);
    }
  };

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7 mb-8" fluid>
        <Card className="bg-default shadow">
          <CardHeader className="bg-transparent border-0">
            <Row className="align-items-center">
              <div className="col">
                <h2 className="text-white mb-0 text-center">Members</h2>
              </div>
              <div className="col text-right">
                <Button color="primary" onClick={() => history.push("/customer/add-member")}>
                  Add New Member
                </Button>
              </div>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-dark table-flush" responsive>
            <thead className="thead-dark">
              <tr>
                <th>No</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {members.map((member, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{member.first_name}</td>
                  <td>{member.last_name}</td>
                  <td>{member.email}</td>
                  <td>{member.phone}</td>
                  <td>
                    <Button color="info" size="sm" onClick={() => onEdit(member.id)}>
                      Edit
                    </Button>
                    <Button color="danger" size="sm" onClick={() => onDelete(member.id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
}

export default MemberList;
