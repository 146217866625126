import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import AddClubHeader from "../../components/Headers/AddClubHeader.js";
import helper from "../../services/helper.service";
import adminApi from "../../services/admin.service";

function AdminAirTag() {
  const [airTags, setAirTags] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [newAirTag, setNewAirTag] = useState({ name: "", tracking_id: "", club_id: "" });
  const [isEdit, setIsEdit] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchAirTags();
    fetchClubs();
  }, []);

  const fetchAirTags = () => {
    adminApi.getAirTags().then((response) => {
      if (response.status === 200) {
        setAirTags(response.data);
      } else {
        helper.showToast("Error", "Failed to fetch AirTags.", 3);
      }
    });
  };

  const fetchClubs = () => {
    adminApi.getClubList().then((response) => {
      if (response.status === 200) {
        setClubs(response.data);
      }
    });
  };
  const handleInputChange = (index, field, value) => {
    setAirTags((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated;
    });
  };

  const handleNewAirTagChange = (field, value) => {
    setNewAirTag((prev) => ({ ...prev, [field]: value }));
  };

  const onEdit = (index) => {
    setActiveIndex(index);
    setIsEdit(true);
  };

  const onSave = () => {
    const airTag = airTags[activeIndex];
    adminApi.updateAirTag(airTag).then((response) => {
      if (response.status === 200) {
        helper.showToast("Success", "AirTag updated successfully.", 1);
        setIsEdit(false);
        fetchAirTags();
      } else {
        helper.showToast("Error", "Failed to update AirTag.", 3);
      }
    });
  };

  const onCreate = () => {
    adminApi.createAirTag(newAirTag).then((response) => {
      if (response.status === 200) {
        helper.showToast("Success", "AirTag created successfully.", 1);
        setNewAirTag({ name: "", tracking_id: "", club_id: "" });
        setIsModalOpen(false);
        fetchAirTags();
      } else {
        helper.showToast("Error", "Failed to create AirTag.", 3);
      }
    });
  };

  return (
    <>
      <AddClubHeader />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
                <h3 className="text-white mb-0">AirTags Management</h3>
                <Button color="primary" onClick={() => setIsModalOpen(true)}>Create AirTag</Button>
              </CardHeader>
              <Table className="align-items-center table-dark table-flush" responsive>
                <thead className="thead-dark">
                  <tr>
                    <th>Name</th>
                    <th>Tracking ID</th>
                    <th>Club</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {airTags.map((airTag, index) => (
                    isEdit && activeIndex === index ? (
                      <tr key={index}>
                        <td>
                          <Input value={airTag.name} onChange={(e) => handleInputChange(index, "name", e.target.value)} />
                        </td>
                        <td>
                          <Input value={airTag.tracking_id} onChange={(e) => handleInputChange(index, "tracking_id", e.target.value)} />
                        </td>
                        <td>
                          <Input
                            type="select"
                            value={airTag.club_id || ""}
                            onChange={(e) => handleInputChange(index, "club_id", e.target.value)}
                          >
                            <option value="">Unattached</option>
                            {clubs.map((club) => (
                              <option key={club.id} value={club.id}>{club.name}</option>
                            ))}
                          </Input>
                        </td>
                        <td>
                          <Button color="success" onClick={onSave}>Save</Button>
                          <Button color="warning" onClick={() => setIsEdit(false)}>Cancel</Button>
                        </td>
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td>{airTag.name}</td>
                        <td>{airTag.tracking_id}</td>
                        <td>{clubs.find(club => club.id === airTag.club_id)?.name || "Unattached"}</td>
                        <td>
                          <Button color="info" onClick={() => onEdit(index)}>Edit</Button>
                        </td>
                      </tr>
                    )
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Create New AirTag</ModalHeader>
        <ModalBody>
          <Form>
            <Input placeholder="Name" value={newAirTag.name} onChange={(e) => handleNewAirTagChange("name", e.target.value)} />
            <Input placeholder="Tracking ID" value={newAirTag.tracking_id} onChange={(e) => handleNewAirTagChange("tracking_id", e.target.value)} />
            <Input type="select" value={newAirTag.club_id} onChange={(e) => handleNewAirTagChange("club_id", e.target.value)}>
              <option value="">Unattached</option>
              {clubs.map((club) => (
                <option key={club.id} value={club.id}>{club.name}</option>
              ))}
            </Input>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onCreate}>Create</Button>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default AdminAirTag;
