import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useHistory, useParams } from 'react-router-dom';
import AddClubHeader from "../../components/Headers/AddClubHeader.js";
import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

function AddCaddie() {
  const history = useHistory();
  const { caddieId } = useParams(); // This will capture the caddieId from the URL
  const [clubId, setClubId] = useState(session.get('clubInfo').clubId);
  
  // Form Fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  // State for managing form submission
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (caddieId) {
      // If caddieId is present, fetch caddie data for editing
      customerApi.getCaddieById({caddie_id: caddieId}).then(response => {
        if (response.status === 200) {
          const caddie = response.data;
          setFirstName(caddie.first_name);
          setLastName(caddie.last_name);
          setPhone(caddie.phone);
          setEmail(caddie.email);
          setIsEdit(true);
        } else {
          helper.showToast("Error", "Caddie not found", 3);
        }
      });
    }
  }, [caddieId]);

  const submitCaddie = async () => {
    const caddieData = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      email: email,
      club_id: clubId
    };

    try {
      if (isEdit) {
        // Edit Caddie
        const response = await customerApi.updateCaddie({ caddie_id: caddieId, ...caddieData});
        if (response.status === 200) {
          helper.showToast('Success', 'Caddie updated successfully.', 1);
          history.push('/customer/caddies');  // Redirect to caddie list after success
        } else {
          helper.showToast('Error', 'Failed to update caddie.', 3);
        }
      } else {
        // Create New Caddie
        const response = await customerApi.addCaddie(caddieData);
        if (response.status === 200) {
          helper.showToast('Success', 'Caddie added successfully.', 1);
          history.push('/customer/caddies');  // Redirect to caddie list after success
        } else {
          helper.showToast('Error', 'Failed to add caddie.', 3);
        }
      }
    } catch (error) {
      console.error("Error saving caddie: ", error);
      helper.showToast('Error', 'Invalid caddie details', 3);
    }
  };

  return (
    <>
      <AddClubHeader />
      <Container className="mt--9" fluid>
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{isEdit ? 'Edit Caddie' : 'Add Caddie'}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">Caddie Information</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">First Name</label>
                          <Input
                            type="text"
                            value={firstName}
                            placeholder="John"
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Last Name</label>
                          <Input
                            type="text"
                            value={lastName}
                            placeholder="Doe"
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Phone</label>
                          <Input
                            type="text"
                            value={phone}
                            placeholder="(123) 456-7890"
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Email</label>
                          <Input
                            type="email"
                            value={email}
                            placeholder="john@example.com"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row dir='rtl' style={{marginRight: 10}}>
                      <Button color="primary" onClick={submitCaddie} size="sm">
                        {isEdit ? 'Save Changes' : 'Add Caddie'}
                      </Button>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddCaddie;
