import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import AddClub from "../views/AddClub";
import EditClub from "../views/EditClub";
import EditBox from "../views/EditBox";
import DisplayStatus from "../views/DisplayStatus";
import Summary from "../views/Summary";
import SetTime from "../views/SetTime";
import SetName from "../views/SetName";
import Logout from "../views/Logout";
import HistoryAnalysis from "../views/HistoryAnalysis";
import HistoryList from "../views/HistoryList";
import AdminSummary from "../views/AdminSummary";
import DisplayMap from "../views/DisplayMap";
import EditGroup from "../views/EditGroup";
import AddMember from "../views/AddMember";
import MemberList from "../views/Members";
import AddCaddie from "../views/AddCaddie";
import CaddieList from "../views/Caddies";
import ClubMembers from "../views/ClubMembers";
import AdminAirTag from "../views/AdminAirTags";
import GameList from "../views/GameList";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/activegolfers",
    name: "Active Golfers",
    icon: "ni ni-tv-2 text-primary",
    component: AdminSummary,
    layout: "/admin",
  },
  {
    path: "/addclub",
    name: "Add Club",
    icon: "ni ni-chart-pie-35 text-primary",
    component: AddClub,
    layout: "/admin",
  },

  {
    path: "/editclub",
    name: "Edit Club",
    icon: "ni ni-chart-pie-35 text-primary",
    component: EditClub,
    layout: "/admin",
  },
  {
    path: "/club-members",
    name: "Club Member",
    icon: "ni ni-chart-pie-35 text-primary",
    component: ClubMembers,
    layout: "/admin",
  },
  {
    path: "/airtags",
    name: "Air Tags",
    icon: "ni ni-chart-pie-35 text-primary",
    component: AdminAirTag,
    layout: "/admin",
  },
  {
    path: "/editgroup",
    name: "Edit Group",
    icon: "ni ni-chart-pie-35 text-primary",
    component: EditGroup,
    layout: "/admin",
  },

  {
    path: "/editbox",
    name: "Edit Box",
    icon: "ni ni-chart-pie-35 text-primary",
    component: EditBox,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Club Dashboard",
    icon: "ni ni-chart-pie-35 text-primary",
    component: Summary,
    layout: "/admin",
  },

  {
    path: "/clubstatus",
    name: "Golf Status",
    icon: "ni ni-chart-pie-35 text-primary",
    component: DisplayStatus,
    layout: "/admin",
  },

  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-chart-pie-35 text-primary",
    component: Logout,
    layout: "/admin",
  },

  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: GameList,
    layout: "/customer",
  },

  {
    path: "/status",
    name: "Individual Status",
    icon: "ni ni-tv-2 text-primary",
    component: DisplayStatus,
    layout: "/customer",
  },

  {
    path: "/course",
    name: "Map",
    icon: "ni ni-tv-2 text-primary",
    component: DisplayMap,
    layout: "/customer",
  },

  {
    path: "/settime",
    name: "Set Time",
    icon: "ni ni-tv-2 text-primary",
    component: SetTime,
    layout: "/customer",
  },
  {
    path: "/members",
    name: "Members",
    icon: "ni ni-tv-2 text-primary",
    component: MemberList,
    layout: "/customer",
  },
  {
    path: "/add-member",
    name: "Add Member",
    hide: true,
    icon: "ni ni-tv-2 text-primary",
    component: AddMember,
    layout: "/customer",
  },
  {
    path: "/edit-member/:memberId",
    name: "Edit Member",
    hide: true,
    icon: "ni ni-tv-2 text-primary",
    component: AddMember,
    layout: "/customer",
  },
  {
    path: "/caddies",
    name: "Caddies",
    icon: "ni ni-tv-2 text-primary",
    component: CaddieList,
    layout: "/customer",
  },
  {
    path: "/add-caddie",
    name: "Add Caddie",
    hide: true,
    icon: "ni ni-tv-2 text-primary",
    component: AddCaddie,
    layout: "/customer",
  },
  {
    path: "/edit-caddie/:caddieId",
    name: "Edit Caddie",
    hide: true,
    icon: "ni ni-tv-2 text-primary",
    component: AddCaddie,
    layout: "/customer",
  },
  {
    path: "/setname",
    name: "Set Name",
    icon: "ni ni-tv-2 text-primary",
    component: SetName,
    layout: "/customer",
  },
  {
    path: "/historylist",
    name: "History Analysis",
    icon: "ni ni-tv-2 text-primary",
    component: HistoryList,
    layout: "/customer",
  },
  {
    path: "/historyanalysis",
    name: "History",
    icon: "ni ni-tv-2 text-primary",
    component: HistoryAnalysis,
    layout: "/customer",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-chart-pie-35 text-primary",
    component: Logout,
    layout: "/customer",
  },

];
export default routes;
