import { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Col,
  Media,
  Input,
  Table,
  Container,
  Row,
  Button,
  Form
} from "reactstrap";

import { useHistory } from 'react-router-dom';
import AddClubHeader from "../../components/Headers/AddClubHeader.js";
import helper from '../../services/helper.service';
import adminApi from '../../services/admin.service';
import session from '../../services/session.service';

function EditClubMembers(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const [clubId, setClubId] = useState(0);
  const [clubName, setClubName] = useState("");
  const [members, setMembers] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [file, setFile] = useState();
  const fileReader = new FileReader();

  useEffect(() => {
    const { state } = props.location;
    const club = state.club;
    if (!isRead) {
      setIsRead(true);
      setClubId(club.clubId);
      setClubName(club.name);
      getMembers(club.clubId);
    }
  }, []);

  const getMembers = (clubId) => {
    adminApi.getMembers({ club_id: clubId })
      .then((response) => {
        if (response.status === 200) {
          setMembers(response.data);
        } else {
          helper.showToast('Error', 'Failed to get members.', 3);
        }
      })
      .catch((error) => {
        helper.showToast('Error', 'Invalid auth', 3);
      });
  };

  const onEdit = (index) => {
    setActiveIndex(index);
    setIsEdit(true);
  };

  const onSave = () => {
    const member = members[activeIndex];
    adminApi.updateMember({...member, member_id: member.id})
      .then((response) => {
        if (response.status === 200) {
          helper.showToast('Success', 'Successfully Updated.', 1);
          setIsEdit(false);
          setIsRead(false);
        } else {
          helper.showToast('Error', 'Failed to update member.', 3);
        }
      })
      .catch((error) => {
        helper.showToast('Error', 'Invalid club', 3);
      });
  };

  const handleInputChange = (index, field, value) => {
    setMembers(prevMembers => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = { ...updatedMembers[index], [field]: value };
      return updatedMembers;
    });
  };

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        csvFileToArray(csvOutput);
      };
      fileReader.readAsText(file);
    }
  };

  const csvFileToArray = (string) => {
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    const array = csvRows.map(i => {
      const values = i.split(",");
      return {
        first_name: values[0],
        last_name: values[1],
        email: values[2],
        phone: values[3],
        club_id: clubId
      };
    });
    adminApi.uploadMembers({ club_id: clubId, members: array })
      .then((response) => {
        if (response.status === 200) {
          helper.showToast('Success', 'Successfully Updated.', 1);
          setIsEdit(false);
          setIsRead(false);
        } else {
          helper.showToast('Error', 'Failed to update members.', 3);
        }
      })
      .catch((error) => {
        helper.showToast('Error', 'Invalid club', 3);
      });
  };

  return (
    <>
      <AddClubHeader />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="text-white mb-0">{clubName}</h3>
                  </div>
                  <Form>
                    <input type="file" accept=".csv" onChange={handleOnChange} />
                    <Button onClick={handleOnSubmit}>IMPORT CSV</Button>
                  </Form>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-dark table-flush" responsive>
                <thead className="thead-dark">
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((member, index) => (
                    isEdit && activeIndex === index ? (
                      <tr key={index}>
                        <td><Input value={member.first_name} onChange={(e) => handleInputChange(index, 'first_name', e.target.value)} /></td>
                        <td><Input value={member.last_name} onChange={(e) => handleInputChange(index, 'last_name', e.target.value)} /></td>
                        <td><Input value={member.email} onChange={(e) => handleInputChange(index, 'email', e.target.value)} /></td>
                        <td><Input value={member.phone} onChange={(e) => handleInputChange(index, 'phone', e.target.value)} /></td>
                        <td>
                          <Button color="success" onClick={onSave}>OK</Button>
                          <Button color="warning" onClick={() => setIsEdit(false)}>Cancel</Button>
                        </td>
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td>{member.first_name}</td>
                        <td>{member.last_name}</td>
                        <td>{member.email}</td>
                        <td>{member.phone}</td>
                        <td>
                          <Button color="info" onClick={() => onEdit(index)}>Edit</Button>
                        </td>
                      </tr>
                    )
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EditClubMembers;
