import { useState, useEffect } from "react";
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import CustomerHeader from "../../components/Headers/CustomerHeader";
import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

function CaddieList() {
  const [caddies, setCaddies] = useState([]);
  const history = useHistory();
  const clubId = session.get('clubInfo').clubId;

  useEffect(() => {
    // Fetch the list of caddies
    customerApi.getCaddies({ club_id: clubId }).then((response) => {
      if (response.status === 200) {
        setCaddies(response.data);
      } else {
        helper.showToast("Error", "Failed to load caddies", 3);
      }
    });
  }, [clubId]);

  const onEdit = (caddieId) => {
    history.push(`/customer/edit-caddie/${caddieId}`);
  };

  const onDelete = async (caddieId) => {
    try {
      const response = await customerApi.deleteCaddie({caddie_id: caddieId});
      if (response.status === 200) {
        helper.showToast("Success", "Caddie deleted successfully", 1);
        setCaddies(caddies.filter((caddie) => caddie.id !== caddieId)); // Remove from list
      } else {
        helper.showToast("Error", "Failed to delete caddie", 3);
      }
    } catch (error) {
      helper.showToast("Error", "Failed to delete caddie", 3);
    }
  };

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7 mb-8" fluid>
        <Card className="bg-default shadow">
          <CardHeader className="bg-transparent border-0">
            <Row className="align-items-center">
              <div className="col">
                <h2 className="text-white mb-0 text-center">Caddies</h2>
              </div>
              <div className="col text-right">
                <Button color="primary" onClick={() => history.push("/customer/add-caddie")}>
                  Add New Caddie
                </Button>
              </div>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-dark table-flush" responsive>
            <thead className="thead-dark">
              <tr>
                <th>No</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {caddies.map((caddie, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{caddie.first_name}</td>
                  <td>{caddie.last_name}</td>
                  <td>{caddie.email}</td>
                  <td>{caddie.phone}</td>
                  <td>
                    <Button color="info" size="sm" onClick={() => onEdit(caddie.id)}>
                      Edit
                    </Button>
                    <Button color="danger" size="sm" onClick={() => onDelete(caddie.id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
}

export default CaddieList;
