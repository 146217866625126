import http from "./http.service";
import session from "./session.service";

const path = {
   
    getBoxTimeList: 'customer/getBoxTimeList',
    updateBoxTimeList: 'customer/updateBoxTimeList',
    getGolferList: 'customer/getGolferList',
    getPrevGolferList: 'customer/getPrevGolferList',
    getUserList: 'customer/getUserList',
    updateUserList: 'customer/updateUserList',
    getIndividualStatus: 'customer/getIndividualStatus',
    getPrevIndividualStatus: 'customer/getPrevIndividualStatus',
    deleteGolfer: 'customer/deleteGolfer',
    getReportHole: 'customer/getReportHole',
    getReportHalfRound: 'customer/getReportHalfRound',
    getReportRound: 'customer/getReportRound',
    getReportSummary: 'customer/getReportSummary',
    deleteGolferRecord: 'customer/deleteGolferRecord',
    getMembers: 'customer/getMembers',
    getMemberById: 'customer/getMemberById',
    updateMember: 'customer/updateMember',
    addMember: 'customer/addMember',
    deleteMember: 'customer/deleteMember',
    getCaddies: 'customer/getCaddies',
    getCaddieById: 'customer/getCaddieById',
    updateCaddie: 'customer/updateCaddie',
    addCaddie: 'customer/addCaddie',
    deleteCaddie: 'customer/deleteCaddie',
    getGames: 'customer/getGames',
    createGame: 'customer/createGame',
    getAirtags: 'customer/getAirtags',

}

export default {
  
    getBoxTimeList: function(body){
        return http.post(path.getBoxTimeList, body, {});
    },
    getGames: function(body){
        return http.post(path.getGames, body, {});
    },
    createGame: function(body){
        return http.post(path.createGame, body, {});
    },
    getAirtags: function(body){
        return http.post(path.getAirtags, body, {});
    },
    getMembers: function(body){
        return http.post(path.getMembers, body, {});
    },
    getMemberById: function(body){
        return http.post(path.getMemberById, body, {});
    },
    updateMember: function(body){
        return http.post(path.updateMember, body, {});
    },
    addMember: function(body){
        return http.post(path.addMember, body, {});
    },
    deleteMember: function(body){
        return http.post(path.deleteMember,body, {});
    },
    getCaddies: function(body){
        return http.post(path.getCaddies, body, {});
    },
    getCaddieById: function(body){
        return http.post(path.getCaddieById, body, {});
    },
    updateCaddie: function(body){
        return http.post(path.updateCaddie, body, {});
    },
    addCaddie: function(body){
        return http.post(path.addCaddie, body, {});
    },
    deleteCaddie: function(body){
        return http.post(path.deleteCaddie,body, {});
    },
    updateBoxTimeList: function(body){
        return http.post(path.updateBoxTimeList, body, {});
    },
    getGolferList: function(body){
        return http.post(path.getGolferList, body, {});
    },
    getPrevGolferList: function(body){
        return http.post(path.getPrevGolferList, body, {});
    },
    getIndividualStatus: function(body){
        return http.post(path.getIndividualStatus, body, {});
    },
    getPrevIndividualStatus: function(body){
        return http.post(path.getPrevIndividualStatus, body, {});
    },
    getUserList: function(body){
        return http.post(path.getUserList, body, {});
    },
    updateUserList: function(body){
        return http.post(path.updateUserList, body, {});
    },
    deleteGolfer: function(body){
        return http.post(path.deleteGolfer, body, {});
    },
    getReportHole: function(body){
        return http.post(path.getReportHole, body, {});
    },
    getReportHalfRound: function(body){
        return http.post(path.getReportHalfRound, body, {});
    },
    getReportRound: function(body){
        return http.post(path.getReportRound, body, {});
    },
    getReportSummary: function(body){
        return http.post(path.getReportSummary, body, {});
    },
    deleteGolferRecord: function(body){
        return http.post(path.deleteGolferRecord, body, {});
    },
}