import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import customerApi from '../../services/customer.service';
import helper from '../../services/helper.service';
import session from '../../services/session.service';
import AddClubHeader from '../../components/Headers/AddClubHeader';

function GameList() {
  const clubId = session.get('clubInfo').clubId;
  const [games, setGames] = useState([]);
  const [members, setMembers] = useState([]);
  const [caddies, setCaddies] = useState([]);
  const [airtags, setAirtags] = useState([]);
  const [newGame, setNewGame] = useState({
    type: '9',
    status: 'created',
    tracker_type: 'airtag',
    tracker_id: '',
    selectedMembers: [],
    selectedCaddies: [],
    club_id: clubId
  });
  const [modal, setModal] = useState(false);

  useEffect(() => {
    fetchGames();
    fetchMembers();
    fetchCaddies();
    fetchAirtags();
  }, []);

  const fetchGames = async () => {
    const response = await customerApi.getGames({ club_id: clubId });
    if (response.status === 200) {
      setGames(response.data);
    }
  };

  const fetchMembers = async () => {
    const response = await customerApi.getMembers({ club_id: clubId });
    if (response.status === 200) {
      setMembers(response.data);
    }
  };

  const fetchCaddies = async () => {
    const response = await customerApi.getCaddies({ club_id: clubId });
    if (response.status === 200) {
      setCaddies(response.data);
    }
  };

  const fetchAirtags = async () => {
    const response = await customerApi.getAirtags({ club_id: clubId });
    if (response.status === 200) {
      setAirtags(response.data);
    }
  };

  const createGame = async () => {
    const response = await customerApi.createGame(newGame);
    if (response.status === 200) {
      helper.showToast('Success', 'Game created successfully.', 1);
      fetchGames();
      setModal(false);
    } else {
      helper.showToast('Error', 'Failed to create game.', 3);
    }
  };
  const updateGameStatus = async (gameId, status) => {
    const response = await customerApi.updateGameStatus({ gameId, status, club_id: clubId });
    if (response.status === 200) {
      helper.showToast('Success', 'Game status updated.', 1);
      fetchGames();
    }
  };
  return (
    <>
      <AddClubHeader/>
      <Container className="mt-4" fluid>
        <Row>
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="bg-white border-0 d-flex justify-content-between">
                <h3 className="mb-0">Games</h3>
                <Button color="primary" onClick={() => setModal(true)}>Create Game</Button>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Members</th>
                      <th>Caddies</th>
                      {/* <th>Status</th> */}
                      <th>Tracker</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {games.map((game) => (
                      <tr key={game.id}>
                        <td>{game.type} Holes</td>
                        <td>{game.members.map((member) => `${member.first_name} ${member.last_name}`).join(' , ')}</td>
                        <td>{game.caddies.map((caddie) => `${caddie.first_name} ${caddie.last_name}`).join(' , ')}</td>
                        {/* <td>
                          <Input
                            type="select"
                            value={game.status}
                            onChange={(e) => updateGameStatus(game.id, e.target.value)}
                          >
                            <option value="created">Created</option>
                            <option value="in-progress">In Progress</option>
                            <option value="completed">Completed</option>
                          </Input>
                        </td> */}
                        <td>{game.tracker_type} - {airtags.find(airtag => airtag.tracking_id === game.tracker_id)?.name}</td>
                        {/* <td>
                          <Button color="danger" size="sm">Delete</Button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal(!modal)}>Create New Game</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <label>Game Type</label>
                <Input
                  type="select"
                  value={newGame.type}
                  onChange={(e) => setNewGame({ ...newGame, type: e.target.value })}
                >
                  <option value={'9'}>9 Holes</option>
                  <option value={'18'}>18 Holes</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <label>Tracker</label>
                <Select
                  options={airtags.map(tag => ({
                    value: tag.tracking_id,
                    label: `airtag: ${tag.name}`
                  }))}
                  onChange={(selected) => setNewGame({ ...newGame, tracker_id: selected.value })}
                />
              </FormGroup>
              <FormGroup>
                <label>Select Members</label>
                <Select
                  isMulti
                  options={members.map(member => ({
                    value: member.id,
                    label: `${member.first_name} ${member.last_name} (${member.email} - ${member.phone})`
                  }))}
                  onChange={(selected) => setNewGame({ ...newGame, selectedMembers: selected.map(s => s.value) })}
                />
              </FormGroup>
              <FormGroup>
                <label>Select Caddies</label>
                <Select
                  isMulti
                  options={caddies.map(caddie => ({
                    value: caddie.id,
                    label: `${caddie.first_name} ${caddie.last_name} (${caddie.email} - ${caddie.phone})`
                  }))}
                  onChange={(selected) => setNewGame({ ...newGame, selectedCaddies: selected.map(s => s.value) })}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={createGame}>Create Game</Button>{' '}
            <Button color="secondary" onClick={() => setModal(false)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
}

export default GameList;
